import ky from 'ky';

import type { EMessageChannel } from 'src/services/api';
import { createBearerHeader } from 'src/common/api-utils';
import { Config } from 'src/config';

export interface IRequestSmsPhoneNumberResponse {
    phone_number: string;
}

/**
 * @throws {HTTPError} on failure
 */
export async function requestSmsPhoneNumber(accessToken: string): Promise<IRequestSmsPhoneNumberResponse> {
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/messages/associate-phone-number';
    const headers = createBearerHeader(accessToken);
    return ky.post(url.toString(), {
        headers: headers,
    }).json() as Promise<IRequestSmsPhoneNumberResponse>;
}


export enum ReadStatus {
    READ = 'read',
    UNREAD = 'unread',
}

export interface IConversation {
    /**
     * This may be null
     */
    property_id: number | null
    first_contact_at: string;
    last_contact_at: string;
    /**
     * This is guaranteed to always be set
     */
    lead_uuid: string;
    /**
     * How the lead was most recently contacted
     * Guaranteed to never be null
     */
    latest_message_channel: EMessageChannel;
    /**
     * Number of unread messages for that specific lead
     */
    num_unread_messages: number;
    /**
     * outbound relative to the landlord
     */
    num_outbound_messages: number;
    /**
     * relative to the landlord
     */
    last_message_direction: 'in' | 'out';
    latest_message_contents: string;

    first_outbound_message_at: string | null;
    last_inbound_message_at: string | null;
}

export default {
    requestSmsPhoneNumber,
};
