import { EMessageChannel } from 'src/services/api';

type SupportedSignatureChannel = EMessageChannel.SMS | EMessageChannel.EMAIL;

export const isSupportedSignatureChannel = (channel: EMessageChannel): channel is SupportedSignatureChannel => {
    return [EMessageChannel.SMS, EMessageChannel.EMAIL].includes(channel);
};

export type GetUserSettingsResponse = {
    settings: {
        auto_archive_lead_after_days: number;

        signatures: {
            sms: {
                is_enabled: boolean;
                text: string;
            };

            email: {
                is_enabled: boolean;
                text: string;
            };

            // TODO: Uncomment this when the feature is implemented
            // fb_messenger: {
            //     is_enabled: boolean;
            //     text: string;
            // };
        };

        notifications: {
            new_message: 'all' | 'owner_only' | 'off';
        };

        conversations_hidden_lead_label_ids: number[] | null;
    };
}

export type UserSettings = GetUserSettingsResponse['settings'];
