import type { IProperty, ILead } from 'src/services/api';
import { ELeadStage } from 'src/services/api';
import type { IConversation } from 'src/api/landlord-messages-api';
import { EConversationTab, type TLandlordConversationsContext } from 'src/pages/landlord/conversations/context/types';
import { searchConversations } from 'src/pages/landlord/conversations/utils/search-conversations';

const filterByTab = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    tab: TLandlordConversationsContext['tab'],
) => {
    return conversations.filter((conversation) => {
        const lead = leadsByUuid.get(conversation.lead_uuid);
        if (!lead) { return false; }

        switch (tab) {
            case EConversationTab.ALL:
                return true;
            case EConversationTab.NEW:
                return lead.stage === ELeadStage.NEW;
            case EConversationTab.CONTACTED:
                return lead.stage === ELeadStage.CONTACTED;
            case EConversationTab.TOUR:
                return [ELeadStage.TOUR_REQUESTED, ELeadStage.TOUR_SCHEDULED, ELeadStage.TOUR_COMPLETED]
                    .includes(lead.stage);
            case EConversationTab.APPLICATION:
                return [ELeadStage.INVITED_TO_APPLY, ELeadStage.APPLICATION_COMPLETED, ELeadStage.APPLICATION_APPROVED]
                    .includes(lead.stage);
            case EConversationTab.NOT_QUALIFIED:
                return lead.stage === ELeadStage.NOT_QUALIFIED;
            case EConversationTab.NOT_INTERESTED:
                return lead.stage === ELeadStage.NOT_INTERESTED;
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _assertNever: never = tab;
            }
        }
    });
};

const filterByAssignee = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    assigneeIds: string[],
) => {
    if (!assigneeIds.length) {
        return conversations;
    }

    return conversations.filter((conversation) => {
        const lead = leadsByUuid.get(conversation.lead_uuid);
        return assigneeIds.includes(String(lead?.owner));
    });
};

const filterByLeadStages = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    leadStages: ELeadStage[],
) => {
    if (!leadStages.length) {
        return conversations;
    }

    return conversations.filter((conversation) => {
        const lead = leadsByUuid.get(conversation.lead_uuid);
        if (!lead) { return false; }
        return leadStages.includes(lead.stage);
    });
};

const filterByLeadLabels = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    leadLabelIds: string[],
) => {
    if (!leadLabelIds.length) {
        return conversations;
    }

    return conversations.filter((conversation) => {
        return leadLabelIds.some((filterLabelId) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            return lead?.labels.some((label) => label.id === Number(filterLabelId));
        });
    });
};

const filterByExcludedLeadLabels = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    excludeLeadLabelIds: string[],
) => {
    if (!excludeLeadLabelIds.length) {
        return conversations;
    }

    return conversations.filter((conversation) => {
        return excludeLeadLabelIds.every((filterLabelId) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            return !lead?.labels.some((label) => label.id === Number(filterLabelId));
        });
    });
};

const filterByProperty = (
    conversations: IConversation[],
    propertyIds: string[],
    hasNoProperty?: boolean,
) => {
    let filteredConversations = conversations;

    if (hasNoProperty) {
        filteredConversations = conversations.filter((conversation) => {
            return !conversation.property_id;
        });
    }

    if (propertyIds.length) {
        filteredConversations = filteredConversations.filter((conversation) => {
            return propertyIds.includes(String(conversation.property_id));
        });
    }

    return filteredConversations;
};

const filterByPropertyLabels = (conversations: IConversation[], properties: IProperty[], propertyLabelIds: string[]) => {
    if (!propertyLabelIds.length) {
        return conversations;
    }

    return conversations.filter((conversation) => {
        const property = properties.find((o) => o.id === conversation.property_id);
        return propertyLabelIds.some((filterLabelId) => {
            return property?.labels.some((label) => label.id === Number(filterLabelId));
        });
    });
};

export const filterConversations = ({
    hiddenLeadLabelIds,
    properties,
    leadsByUuid,
    conversations,
    tab,
    search,
    filter,
}: {
    hiddenLeadLabelIds: number[] | undefined;
    properties: IProperty[] | undefined;
    leadsByUuid: Map<string, ILead>;
    conversations: IConversation[] | undefined;
    tab: TLandlordConversationsContext['tab'];
    search: TLandlordConversationsContext['search'];
    filter: TLandlordConversationsContext['filter'];
}) => {
    if (!properties || !conversations) {
        return [];
    }

    let filteredConversations = conversations;

    if (hiddenLeadLabelIds) {
        filteredConversations = filteredConversations.filter((conversation) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            if (!lead) { return false; }
            return !lead.labels.some((label) => hiddenLeadLabelIds.includes(label.id));
        });
    }

    filteredConversations = filterByTab(leadsByUuid, filteredConversations, tab);
    filteredConversations = filterByAssignee(leadsByUuid, filteredConversations, filter.assigneeIds);
    filteredConversations = filterByLeadStages(leadsByUuid, filteredConversations, filter.leadStages);
    filteredConversations = filterByLeadLabels(leadsByUuid, filteredConversations, filter.leadLabelIds);
    filteredConversations = filterByExcludedLeadLabels(leadsByUuid, filteredConversations, filter.excludeLeadLabelIds);
    filteredConversations = filterByProperty(filteredConversations, filter.propertyIds, filter.hasNoProperty);
    filteredConversations = filterByPropertyLabels(filteredConversations, properties, filter.propertyLabelIds);

    filteredConversations = searchConversations(leadsByUuid, filteredConversations, search);

    return filteredConversations;
};
