import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

import {
    type IGetConversationsResponse,
    apiClient,
    useSubscribeToPushNotifications,
} from 'src/services/api';
import {
    useUpdateConversations,
} from 'src/services/push-notifications/use-update-conversations';

// Copied from the web-push documentation
export const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const useServiceWorkerRegistration = () => {
    const [registration, setRegistration] = useState<ServiceWorkerRegistration>();

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            const register = async () => {
                try {
                    setRegistration(await navigator.serviceWorker.register(
                        '/service-worker--push-notifications.js',
                        {
                            scope: '/',
                        }
                    ));
                } catch (error) {
                    console.error(`Registration failed with ${error}`);
                }
            };

            register();
        }
    }, []);

    return registration;
};

export const transformSubscription = (subscription: PushSubscription) => {
    const subscriptionJson = subscription.toJSON();

    if (subscriptionJson) {
        const { endpoint, keys } = subscriptionJson;
        if (endpoint && keys?.p256dh && keys?.auth) {
            return {
                endpoint,
                keys: {
                    p256dh: keys.p256dh,
                    auth: keys.auth,
                },
            };
        }
    }

    return null;
};

export const usePushNotifications = () => {
    const { mutate: subscribeToPushNotifications } = useSubscribeToPushNotifications();
    const updateConversations = useUpdateConversations();

    const serviceWorkerRegistration = useServiceWorkerRegistration();

    useEffect(() => {
        if (!serviceWorkerRegistration) {
            return;
        }

        const main = async () => {
            const pushSubscription =
        await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(import.meta.env['VITE_VAPID_PUBLIC_KEY']),
        });

            const webPushSubscription = transformSubscription(pushSubscription);
            if (webPushSubscription) {
                subscribeToPushNotifications({
                    endpoint: webPushSubscription.endpoint,
                    p256dh: webPushSubscription.keys.p256dh,
                    auth: webPushSubscription.keys.auth,
                });
            }
        };

        main();
    }, [serviceWorkerRegistration]);

    useEffect(() => {
        const handleMessage = throttle(async (e: MessageEvent) => {
            const message = e.data;
            if (message === 'NEW_LEAD_MESSAGE') {
                const searchParams = new URLSearchParams();
                const since = new Date();
                since.setMinutes(since.getMinutes() - 5);
                searchParams.append('since', since.toISOString().slice(0, -1));
                const res = await apiClient('api/landlord/conversations', {
                    searchParams,
                }).json<IGetConversationsResponse>();

                updateConversations(res.conversations);
            }
        }, 5000, { trailing: false });

        navigator.serviceWorker.addEventListener('message', handleMessage);
        return () => {
            navigator.serviceWorker.removeEventListener('message', handleMessage);
        };
    }, []);
};
