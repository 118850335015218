import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';

import Responsive from 'src/components/responsive/Responsive';
import Flex from 'src/components/flex/Flex';
import { type EConversationCategory } from 'src/pages/landlord/conversations/context/types';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import {
    DesktopConversationListItem,
    MobileConversationListItem,
} from 'src/pages/landlord/conversations/components/list/ConversationListItem';

type Props = {
    category: EConversationCategory;
}

export default function ConversationsTable({ category }: Props) {
    const hash = useLocation().hash;

    const {
        properties,
        selectedConversations,
        setSelectedConversations,
        setFocusedConversation,
        rowsPerPage,
        pagesByCategory,
        setPagesByCategory,
        conversationsByCategory,
        visibleConversationsByCategory,
    } = useConversationsPageContext();

    const [expanded, setExpanded] = useState(true);

    const page = pagesByCategory[category];
    const conversations = conversationsByCategory[category];
    const visibleConversations = visibleConversationsByCategory[category];

    const pageCount = Math.ceil((conversations?.length || 0) / rowsPerPage);

    const numberOfConversations = conversations.length;

    let endIndex = page * rowsPerPage;
    if (endIndex > numberOfConversations) {
        endIndex = numberOfConversations;
    }
    const startIndex = (page - 1) * rowsPerPage + 1;

    const hasConversations = !!conversations.length;

    const allSelected = hasConversations &&
        visibleConversations.every((conversation) => {
            return selectedConversations.some((
                selectedConversation
            ) => selectedConversation.lead_uuid === conversation.lead_uuid);
        });

    const selectAll = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (allSelected) {
            setSelectedConversations(prev => {
                return prev.filter((conversation) => {
                    return !visibleConversations.some((
                        selectedConversation
                    ) => selectedConversation.lead_uuid === conversation.lead_uuid);
                });
            });
        } else {
            setSelectedConversations(prev => {
                const newSelectedConversations = visibleConversations.filter((conversation) => {
                    return !prev.some((
                        selectedConversation
                    ) => selectedConversation.lead_uuid === conversation.lead_uuid);
                });

                return [...prev, ...newSelectedConversations];
            });
        }
    };

    const setPage = (newPage: number) => {
        setPagesByCategory(prev => ({
            ...prev,
            [category]: newPage
        }));
        setSelectedConversations([]);
        setFocusedConversation(undefined);
    };

    const checkboxOpacity = (hasConversations && selectedConversations.length > 0) ? 1 : 0;

    // Scroll to the category according to the hash in the URL
    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [hash]);

    const propertiesById = useMemo(() => {
        return new Map(properties.map((o) => [o.id, o]));
    }, [properties]);

    return (
        <Accordion
            id={category}
            disabled={!hasConversations}
            disableGutters
            square
            elevation={0}
            data-testid={`${category}-section`}
            expanded={expanded}
            onChange={(_, value) => setExpanded(value)}
            sx={{ '::before': { display: 'none' } }}
        >
            <AccordionSummary
                sx={{
                    px: 1,
                    bgcolor: 'grey.100',
                    '& .MuiAccordionSummary-content': { my: 0.5 },
                    '& .MuiCheckbox-root': { opacity: checkboxOpacity },
                    '&:hover .MuiCheckbox-root': { opacity: hasConversations ? 1 : checkboxOpacity },
                }}
            >
                <Flex flex={1} justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Responsive desktop>
                            {/* checkbox table cell width minus left padding and column gap*/}
                            <Box sx={{ width: 'calc(40px - 16px)' }}>
                                <Checkbox
                                    checked={allSelected}
                                    onClick={selectAll}
                                    sx={{
                                        padding: 0,
                                        width: 20,
                                        height: 20,
                                        '& svg': { fontSize: 20 }
                                    }}
                                />
                            </Box>
                        </Responsive>
                        <Flex alignItems="center">
                            {hasConversations && (expanded ?
                                <ExpandLessIcon color="action" /> :
                                <ExpandMoreIcon color="action" />
                            )}
                            <Typography>{category}</Typography>
                        </Flex>
                    </Flex>

                    {pageCount > 0 && (
                        <Flex alignItems="center" onClick={event => event.stopPropagation()}>
                            <Typography variant="caption">
                                {startIndex} - {endIndex} of {conversations.length}
                            </Typography>

                            <Flex columnGap={0} sx={{ '& .MuiIconButton-root': { p: 0 } }}>
                                <IconButton onClick={() => setPage(page - 1)} disabled={page === 1} size="small">
                                    <ChevronLeftIcon />
                                </IconButton>

                                <IconButton onClick={() => setPage(page + 1)} disabled={page === pageCount} size="small">
                                    <ChevronRightIcon />
                                </IconButton>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </AccordionSummary>

            <Responsive desktop>
                <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ overflowX: 'auto' }}>
                        <TableContainer component={Paper} elevation={0} square>
                            <Table size="small" sx={{ tableLayout: 'fixed' }}>
                                <TableBody>
                                    {visibleConversations.map((conversation) => {
                                        const property = propertiesById.get(conversation.property_id!);

                                        return (
                                            <DesktopConversationListItem
                                                key={conversation.lead_uuid}
                                                conversation={conversation}
                                                property={property}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </AccordionDetails>
            </Responsive>

            <Responsive mobile>
                <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ overflowX: 'auto' }}>
                        {visibleConversations.map((conversation) => {
                            const property = propertiesById.get(conversation.property_id!);

                            return (
                                <MobileConversationListItem
                                    key={conversation.lead_uuid}
                                    conversation={conversation}
                                    property={property}
                                />
                            );
                        })}
                    </Box>
                </AccordionDetails>
            </Responsive>
        </Accordion>
    );
}
