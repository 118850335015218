import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import type { IConversation } from 'src/api/landlord-messages-api';
import type { ILead } from 'src/services/api';
import {
    Auth,
    useConversations,
    useLeads,
    useLoggedInUser,
    useNotifications,
    useProperties,
    useTeamSettings,
    useUserSettings
} from 'src/services/api';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';
import LandlordLayout from 'src/components/layout/landlord/LandlordLayout';
import { AuthenticatedStateContext } from 'src/authenticated-state/context';



type Props = {
    children: React.ReactNode;
};

export default function AuthenticatedStateProvider({ children }: Props) {
    const isLoggedIn = !!Auth.accessToken;
    const init = useRef(false);

    const navigate = useNavigate();

    const [showArchived, setShowArchived] = useState(false);
    const [isInactivePropertiesModalClosed, _setIsInactivePropertiesModalClosed] = useState(
        localStorage.getItem('inactive-properties-closed') === 'true'
    );
    const setIsInactivePropertiesModalClosed = useCallback((value: boolean) => {
        _setIsInactivePropertiesModalClosed(value);
        localStorage.setItem('inactive-properties-closed', value ? 'true' : 'false');
    }, []);

    const { data: loggedInUser } = useLoggedInUser();
    const { data: teamSettings } = useTeamSettings();
    const { data: userSettings } = useUserSettings();

    const jwtPayload = Auth.jwtPayload;
    const isBypassPaywall = jwtPayload?.ibp;
    const isPaywallEnabled = !isBypassPaywall && !loggedInUser?.team?.is_active;

    const {
        data: leads,
        dataUpdatedAt: leadsUpdatedAt,
        refetch: refetchLeads,
    } = useLeads({
        disabled: !loggedInUser?.is_onboarding_complete || isPaywallEnabled,
        isArchived: showArchived ? true : false,
        refetchInterval: 20000,
    });
    const leadIngestionSources = useMemo(() => {
        if (!leads) { return []; }

        const sources = new Set<string>();
        leads.forEach((lead) => {
            sources.add(normalizeSource(lead.ingestion_source));
        });

        return Array.from(sources).sort();
    }, [leads]);

    const leadsByUuid = useMemo(() => {
        if (!leads) { return new Map(); }

        return leads.reduce((acc, lead) => {
            acc.set(lead.uuid, lead);
            return acc;
        }, new Map<string, ILead>());
    }, [leads]);

    const {
        data: conversations,
        dataUpdatedAt: conversationsUpdatedAt,
        refetch: refetchConversations,
    } = useConversations({
        disabled: !loggedInUser?.is_onboarding_complete || isPaywallEnabled,
        isArchived: showArchived ? undefined : false,
    });

    const conversationsByLeadUuid = useMemo(() => {
        if (!conversations) { return new Map(); }

        return conversations.reduce((acc, conversation) => {
            acc.set(conversation.lead_uuid, conversation);
            return acc;
        }, new Map<string, IConversation>());
    }, [conversations]);

    // prefetch properties
    useProperties({
        disabled: !loggedInUser?.is_onboarding_complete || isPaywallEnabled,
    });

    // Refetch leads and conversations when showArchived changes
    useEffect(() => {
        if (!Auth.accessToken) { return; }

        if (!loggedInUser?.is_onboarding_complete) { return; }

        if (!init.current) {
            init.current = true;
            return;
        }

        refetchLeads();
        refetchConversations();
    }, [loggedInUser, showArchived]);

    const { data: notifications } = useNotifications(!isLoggedIn);

    const isInactivePropertiesModalOpen = useMemo(() => {
        return !isInactivePropertiesModalClosed && !!notifications?.length;
    }, [isInactivePropertiesModalClosed, notifications]);

    if (!Auth.accessToken) {
        navigate('/landlord/login');
        return null;
    }

    if (!loggedInUser || !teamSettings || !userSettings) {
        return (
            <LandlordLayout isLoading />
        );
    }

    if (!loggedInUser?.is_onboarding_complete) {
        return <Navigate to="/landlord/onboard" replace />;
    }

    const areLeadsOrConversationsLoading = !isPaywallEnabled && (!leads || !conversations);
    const isAuthenticatedDataLoading = !loggedInUser || !teamSettings || !notifications || areLeadsOrConversationsLoading;
    const isLoading = isLoggedIn && isAuthenticatedDataLoading;

    return (
        <AuthenticatedStateContext.Provider
            value={{
                showArchived,
                setShowArchived,

                loggedInUser,
                teamSettings,
                userSettings,

                leads: leads || [],
                leadsUpdatedAt,
                leadsByUuid,
                leadIngestionSources,

                conversations: conversations || [],
                conversationsUpdatedAt,
                conversationsByLeadUuid,

                notifications: notifications || [],

                isPaywallEnabled,

                isInactivePropertiesModalOpen,
                setIsInactivePropertiesModalClosed,
            }}
        >
            {(isLoading || !loggedInUser) ? (
                <LandlordLayout isLoading />
            ) : (
                children
            )}
        </AuthenticatedStateContext.Provider>
    );
}
