
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import dayjs from 'dayjs';

import Auth from 'src/services/api/auth/Auth';
import LandlordLayout from 'src/components/layout/landlord/LandlordLayout';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import AuthenticatedStateProvider from 'src/authenticated-state/AuthenticatedStateProvider';
import PaywallPage from 'src/pages/paywall/Paywall';
import { usePushNotifications } from 'src/services/push-notifications';

import 'src/components/layout/landlord/LandlordLayout.css';

const REFRESH_TOKEN_INTERVAL_MS = 30 * 60 * 1000; // 30 minutes

function AuthenticatedContainer() {
    const { loggedInUser, isPaywallEnabled } = useAuthenticatedState();

    useEffect(() => {
        if (loggedInUser && !loggedInUser.is_onboarding_complete) {
            window.location.href = '/landlord/onboard';
        }
    }, [loggedInUser]);

    // Refresh the access token every 30 minutes
    useEffect(() => {
        const intervalId = window.setInterval(
            Auth.refreshAccessToken,
            REFRESH_TOKEN_INTERVAL_MS
        );

        return () => window.clearInterval(intervalId);
    }, []);

    // Refresh the access token when the user switches back to the tab
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                try {
                    const jwtPayload = Auth.jwtPayload;
                    const now = dayjs();
                    const issuedAt = dayjs.unix(jwtPayload?.iat || 0);

                    // If the token is older than 30 minutes, refresh it
                    if (now.diff(issuedAt, 'minute') > 30) {
                        Auth.refreshAccessToken();
                    }
                } catch (err) {
                    Auth.logout();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    usePushNotifications();

    if (isPaywallEnabled) {
        return (
            <PaywallPage user={loggedInUser} />
        );
    }

    return (
        <LandlordLayout>
            <Outlet />
        </LandlordLayout>
    );
}

export default function AuthenticatedContainerWithProvider() {
    return (
        <AuthenticatedStateProvider>
            <AuthenticatedContainer />
        </AuthenticatedStateProvider>
    );
}